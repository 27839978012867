@font-face {
  src: url('./assets/font/carem.OTF');
  font-family: 'carem';
}

.App {
  font-family: 'carem';
}

.loginContiner {
  width: 100%;
  height: 100vh;
  background-image: url(./assets/img/login.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContiner1 {
  width: 350px;
  height: 500px;
  background-color: rgba(85, 99, 103, 0.9);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.logoLogin {
  width: 200px;
  height: auto;
}

#inputNewLogin {
  width: 260px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  text-align: center;
  color: #eee;
  border: none;
  margin-top: 10px;
}

#inputNewLoginContiner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#BtnNewLoginContiner {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#BtnNewLogin {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #5dade2;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
}

#BtnNewLogin:hover {
  transform: scale(1.04);
}

#BtnNewLogin:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mapDescPage {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

#titlesidesheet {
  height: 40px;
  width: 100%;
  background-color: #eee;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

}

.returnToBackContiner {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: ltr;
  background-color: #305857;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  margin-bottom: 20px;
  padding: 0px 30px;
}

.infoOrderCol {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.infoOrderCol2 {
  margin: 20px 0px;
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.infoOrderCol2Card {
  margin: 20px 0px;
  width: 100%;
  height: 200px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #B9512A;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}
.infoOrderCol2CardD {
  margin: 20px 0px;
  width: 96%;
  height: 200px;
  padding: 30px;
  margin: 0px 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #B9512A;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

.infoClientCol {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cellInfoCardContiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  padding: 30px;
  color: #fff;
}

.cellInfoCardContiner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cellInfoOrder1 {
  width: 100%;
  height: 35px;
  background-color: #B9512A;
  color: #fff;
  border: 2px solid #305857;
  display: flex;
  align-items: center;
  justify-content: center;

}

.cellInfoOrder2 {
  width: 100%;
  height: 35px;
  background-color: #fff;
  border: 2px solid #305857;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.PdfAndExContiner {
  display: flex;
  flex-direction: row-reverse;
}

#DownloadExcelBtnContiner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#BtnApproveOrderInfo:hover,
#DownloadExcelBtnContiner:hover,
#BtnAddٍSetting:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#BtnApproveOrderInfo:active,
#DownloadExcelBtnContiner:active,
#BtnAddٍSetting:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#BtnAddٍSetting {
  width: 250px;
  height: 80px;
  border-radius: 5px;
  background-color: #b9512a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#colSetting {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
}

#inputAdd2Continer {
  margin: 20px;
  padding: 20px 20px;

}

#ContinerInPut {
  direction: rtl;
  display: flex;
  justify-content: space-between;

}

#ContinerInPut label {
  height: 40px;
  display: flex;
  align-items: center;
  width: 33%;

}

#InputTExtDash111 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: #eee 1px solid;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
  outline: none;
}
.css-5ljhhe, [data-css-5ljhhe]{
  width: 110px;
}
#InputTExtDash111textarea {
  width: 260px;
  min-height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: #eee 1px solid;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
  outline: none;
  margin-bottom: 10px;
}

#btnAddContiner {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
}

#btnInfoOffice1 {
  height: 40px;
  width: 100px;
  border-radius: 10px;
  background-color: #b9512a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#btnInfoOffice1:hover,
.btnUpTableSelect:hover,
#AddClientDeptBTN:hover,
#btnPrindPdf:hover,
.btnNAv:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#btnInfoOffice1:active,
.btnUpTableSelect:active,
#AddClientDeptBTN:active,
#btnPrindPdf:active,
.btnNAv:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.upTabeleBtnContiner {
  width: 100%;
  height: 60px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
}
.btnUpTableSelectContiner {
  display: flex;
  align-items: center;
}
.btnUpTableSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 160px;
  height: 36px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #eee;
  color: #fff;
  margin: 0px 5px;
  font-size: 16px;
  font-weight: 200;
}
.btnUpTableSelect1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 160px;
  height: 36px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #aaa;
  color: #fff;
  margin: 0px 5px;
  font-size: 16px;
  font-weight: 200;
}
#SelectStatusContinerDRivNew {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  min-height: 120px;
  width: 96%;
  margin: 20px 2%;

  background-color: rgba(48, 88, 87, 0.5);
  border-radius: 30px;
}

#RowReportDate {
  margin: 0px;
}

#ColReportDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
#date1Repo,
#date2Repo {
  width: 200px;
  height: 40px;
  /* padding-right: 10px; */
  outline: none;
  margin: 5px 0px;
  border: #305857 1px solid;
  border-radius: 5px;
  text-align: center;
}
#AddClientDeptBTN {
  width: 200px;
  height: 40px;
  background-color: #5dade2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: 25px;
}
#infoShipPdfPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#textDescPagecontent{
width: 100%;
overflow: hidden;
white-space: nowrap;
text-align: center;
}
#AppBarPageContiner {
  height: 60px;
  width: 100%;
  background-color: #aaa;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
#textDescPageContinerAdmin2 {
  width: 96%;
  margin: 20px 2%;
}
#headerpdfCOntiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 220px;
  padding: 0px 4%;
}
#textLogoContinerpdf {
  /* direction: ltr; */
  height: 180px;
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: flex-start;
  align-items: center;
  font-size: 45px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  background-color: #eee;
  border-radius: 40px;
}
#contactUsPdf {
  direction: rtl;
  text-align: right;
  line-height: 0.7;
}
#btnPrindPdf{
  width: 150px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #eee;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  font-size: 13px;

}
#lindownpdf {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: 2px;
  margin: 20px 2%;
  background-color: #eee;
}
#textDescPageContiner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
}
#colPdf{
  display: flex;
  align-items: center;
  justify-content: center;
}
#textDescPagecontent{
  margin-top: 10px;
}
#titleOrderPdf{
  height: 40px;
  width: 96%;
  margin: 0px 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  color: #fff;
  border-radius: 10px;
}
#footerPdfContiner1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 12px;
  font-weight: 200;
}
#typeTab{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 30px;
  color:#fff;
  border-radius: 5px;
}
#footerPdfContiner {
  direction: rtl;
  text-align: right;
  width: 92%;
  margin: 0px 4%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#signaturePdf {
  display: flex;
  align-items: center;
 justify-content: flex-end;
  width: 96%;
  height: 2px;
  padding: 20px 2%;
}
#colInfoDriver{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
#contentInfoDrivver{
  width: 250px;
  height: 35px;
  border: solid 1px #dd543c;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}
.OrderByInfoContiner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.OrderByInfo{
  text-align: center;
  height: 40px;
  width: 300px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #5dade2 1px solid;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.OrderByInfoDEs{
  text-align: center;
  min-height: 80px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #5dade2 1px solid;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.OrderByInfoDEsImg{
  width: 300px;
  height: auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border: #5dade2 1px solid;
  border-radius: 5px;
}
.navvBarContiner{
  direction: rtl;
  width: 100%;
  min-height: 60px;
  background-color: #003B4A;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.navvBarContiner1{
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.navvBarContinerN{
  direction: rtl;
  width: 100%;
  min-height: 60px;
  background-color: #003B4A;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TitlNav{
  display: flex;
  color: #fff;
}
.navvBarContiner a{
  color: #003B4A;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .navvBarContiner{
    flex-direction: column;
    justify-content: center;
  
  }
  .navvBarContiner1{
    justify-content: center;
  }
}
.btnNAv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  height: 35px;
  width: 120px;
  padding: 0px 10px;
  margin: 10px;
  cursor: pointer;
  background-color: #fff;
}
.active .btnNAv{
  background-color: #5dade2;
  color: #fff;
}
@page {
  size: auto;
}